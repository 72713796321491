export const convertToMultiPolygon = (arrayBasedMultiPolygon) => ({
  polygons: arrayBasedMultiPolygon.map((polygon) => ({
    vertices: polygon,
  })),
});

export const convertFromDomainToLeafletFormat = (domainFormattedMultiPolygon) =>
  domainFormattedMultiPolygon.polygons.map((polygon) =>
    polygon.vertices.map((vertice) => [vertice.lat, vertice.lng]),
  );

export const convertFromPredefinedAreaToLeafletFormat = function (predefinedAreaPolygon) {
  let converted = [];

  predefinedAreaPolygon.polygons.forEach((singlePolygon) => {
    let polygon = [];
    let vertices = singlePolygon.vertices;
    for (let i = 0; i < vertices.length; i++) {
      polygon.push({ lat: vertices[i].lat, lng: vertices[i].lng });
    }

    converted.push(polygon);
  });
  return converted;
};
