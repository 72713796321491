const ellipsis = '...';
const space = ' ';

export const truncate = (str, n, tryToUseWholeWords) => {
  if (n < 1) {
    return '';
  }

  if (str.length <= n) {
    return str;
  }

  if (n < ellipsis.length) {
    return str.substr(0, n - 1);
  }

  if (str.indexOf(space) >= 0 && tryToUseWholeWords) {
    let lastSpaceIdx = str.lastIndexOf(space);
    if (lastSpaceIdx + ellipsis.length <= n) {
      str = str.substr(0, lastSpaceIdx) + ellipsis;
    } else {
      str = str.substr(0, n - 1) + ellipsis;
    }

    while (str.length > n) {
      lastSpaceIdx = str.lastIndexOf(space);
      str = str.substr(0, lastSpaceIdx) + ellipsis;
    }

    return str;
  } else {
    str = str.substr(0, n - ellipsis.length - 1);
    return str;
  }
};
