<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="6" lg="4">
        <div class="area-list">
          <v-row v-if="!areaEditMode" class="my-4">
            <v-col cols="auto">
              <h2 class="ml-4">{{ $t('areas.createNewArea') }}</h2>
            </v-col>
            <v-col cols="auto">
              <v-btn class="mx-2" fab small color="secondary" @click="startAreaCreation">
                <v-icon dark> fal fa-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card v-if="areaEditMode" class="pa-4">
            <h2 class="pb-2">
              <template v-if="!selectedPredefinedArea">
                {{ $t('areas.createNewArea') }}
              </template>
              <template v-else>
                {{ $t('areas.editArea') }} "{{ selectedPredefinedArea.name }}"
              </template>
            </h2>
            <v-form
              ref="formInstance"
              v-model="valid"
              :value="validAndHasPolygon"
              @submit.prevent="saveArea"
            >
              <v-text-field
                v-model="areaEdit.name"
                :rules="areaNameRules"
                counter="128"
                outlined
                dense
                :label="$t('name')"
              />
              <v-textarea
                v-model="areaEdit.description"
                :rules="areaDescriptionRules"
                counter="500"
                outlined
                dense
                :label="$t('description')"
              />
              <v-row>
                <v-btn
                  aria-label="cancel"
                  color="primary"
                  class="ma-4"
                  rounded
                  @click="cancelAreaEdit"
                >
                  {{ $t('cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  v-if="!selectedPredefinedArea"
                  aria-label="start drawing"
                  color="secondary"
                  class="ma-4"
                  rounded
                  :disabled="startDrawingButtonDisabled"
                  @click="startDrawing"
                >
                  {{ $t('areas.drawInMap') }}
                </v-btn>

                <div>
                  <v-btn
                    aria-label="save area"
                    color="secondary"
                    class="my-4 mr-4"
                    rounded
                    type="submit"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </div>
              </v-row>
              <v-messages
                class="error--text text-center"
                role="alert"
                :value="polygonError ? [$t('form.field.required', { field: $t('areaInMap') })] : []"
              />
            </v-form>
          </v-card>

          <v-card v-if="selectedPredefinedArea && !areaEditMode" class="mb-4 areaDetailsCard">
            <v-card-title class="py-2">{{ selectedPredefinedArea.name }} </v-card-title>
            <v-icon class="close-card-btn" @click="unselectArea"> fal fa-times-circle </v-icon>
            <v-card-text class="py-0">
              <p class="my-1">
                {{ $t('areas.description') }} <b>{{ selectedPredefinedArea.description }}</b>
              </p>
              <p class="my-1">
                {{ $t('areas.createdBy') }}
                <b
                  >{{ selectedPredefinedArea.createdByUsername }}
                  {{ $d(new Date(selectedPredefinedArea.created), 'long') }}</b
                >
              </p>
              <template v-if="selectedPredefinedArea.updatedBy && selectedPredefinedArea.updated">
                <p class="my-1">
                  {{ $t('areas.updatedBy') }}:
                  <b
                    >{{ selectedPredefinedArea.updatedByUsername }}
                    {{ $d(new Date(selectedPredefinedArea.updated), 'long') }}</b
                  >
                </p>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-btn small color="primary" @click="deleteArea(selectedPredefinedArea)">
                {{ $t('delete') }}
                <v-icon right dark> fal fa-trash </v-icon>
              </v-btn>
              <v-spacer />
              <v-btn small color="secondary" @click="startAreaEdit(selectedPredefinedArea)">
                {{ $t('edit') }}
                <v-icon right dark> fal fa-pencil </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-data-table
            ref="areasList"
            class="areas-table"
            :headers="headers"
            :items="predefinedAreas"
            item-key="id"
            :loading="loading"
            sort-by="name"
            single-select
            :search="search"
            disable-pagination
            hide-default-footer
            must-sort
            :no-results-text="$t('areas.noResults')"
            :no-data-text="$t('areas.noData')"
            :item-class="
              (item) =>
                selectedPredefinedArea && item.id == selectedPredefinedArea.id ? 'current' : ''
            "
            @click:row="selectArea"
          >
            <template #top>
              <v-text-field v-model="search" :label="$t('areas.searchText')" class="mx-4" />
            </template>

            <template #[`item.description`]="{ item }">
              {{ stringMaxLength(item.description, 60) }}
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="8">
        <Map
          ref="map"
          height="calc(100vh - 64px)"
          map-type="streets"
          :draw-tools="areaEditMode"
          :limits="customerLimits"
          :predefined-area="selectedPolygon"
          :search-control="true"
          @startPolygon="startedDrawing"
          @stopDrawing="stoppedDrawing"
          @clearedDrawing="stoppedDrawing"
          @ready="mapReady"
          @finishedDrawing="mapFinishedDrawing"
        />
      </v-col>
    </v-row>
    <Confirm ref="confirm" />
  </div>
</template>

<script>
import Map from '@/components/Map';
import { polygon, featureGroup } from 'leaflet';
import {
  convertFromDomainToLeafletFormat,
  convertToMultiPolygon,
  convertFromPredefinedAreaToLeafletFormat,
} from '@/util/polygonConverter';
import Confirm from '@/components/dialog/Confirm.vue';
import * as stringTools from '@/util/stringTools';
import { maxLength, minLength, required } from '@/util/formRules';
import { t } from '@/plugins/i18n';

export default {
  name: 'Areas',

  components: {
    Map,
    Confirm,
  },

  data: () => ({
    valid: null,
    search: '',
    loading: true,
    customerLimits: null,
    selectedPredefinedArea: null,
    selectedPolygon: null,
    justCreatedAreaId: null,
    polygonError: false,
    areaEdit: {
      name: '',
      description: '',
      polygon: null,
    },

    drawnItems: null,
    areaEditMode: false,
    isDrawing: false,
  }),

  computed: {
    headers() {
      return [
        {
          text: t('areas.name'),
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: t('areas.description'),
          align: 'start',
          sortable: false,
          value: 'description',
        },
      ];
    },
    validAndHasPolygon() {
      return this.valid && this.areaEdit.polygon;
    },

    areaNameRules() {
      const field = this.$t('name');

      return [required(field), minLength(field, 3), maxLength(field, 128)];
    },

    areaDescriptionRules() {
      const field = this.$t('description');

      return [maxLength(field, 500)];
    },

    predefinedAreas() {
      return this.$store.state.user.predefinedAreas;
    },

    profile() {
      return this.$store.state.user.profile;
    },

    startDrawingButtonDisabled() {
      return this.isDrawing;
    },
  },

  watch: {
    predefinedAreas() {
      if (this.selectedPredefinedArea) {
        const justUpdatedArea = this.predefinedAreas.find(
          (area) => area.id === this.selectedPredefinedArea.id,
        );
        if (justUpdatedArea) {
          this.selectArea(justUpdatedArea);
        }
      } else if (this.justCreatedAreaId) {
        const justCreatedArea = this.predefinedAreas.find(
          (area) => area.id === this.justCreatedAreaId,
        );
        if (justCreatedArea) {
          this.selectArea(justCreatedArea);
        }
      }
    },
  },

  mounted() {
    this.goTo({ lat: 60.35, lng: 5.25 });
    this.$store.dispatch('user/getPredefinedAreas');
    this.loading = false;
  },
  methods: {
    mapReady() {
      const convertedLimits = convertFromPredefinedAreaToLeafletFormat(
        this.profile.customer.areaLimits,
      );
      this.customerLimits = convertedLimits;
    },

    goTo(latLng) {
      this.center = latLng;
    },

    mapFinishedDrawing(polygons) {
      // not multipolygon, but single
      this.areaEdit.polygon = polygons;
    },

    setAreaInMap(predefinedArea) {
      let map = this.$refs.map.map;
      if (this.drawnItems) {
        map.removeLayer(this.drawnItems);
      }
      this.drawnItems = new featureGroup();
      map.addLayer(this.drawnItems);
      let polyLayers = [];

      let converted = convertFromDomainToLeafletFormat(predefinedArea.multiPolygon);
      let area = polygon(converted, {
        color: 'yellow',
        id: predefinedArea.id,
        name: predefinedArea.name,
      });
      area.id = 'areaPolygon_' + predefinedArea.id;
      area.bindTooltip(predefinedArea.name);
      polyLayers.push(area);
      if (polyLayers.length > 0) {
        // Add the layers to the drawnItems feature group
        for (let layer of polyLayers) {
          this.drawnItems.addLayer(layer);
        }
        let bounds = this.drawnItems.getBounds();
        map.fitBounds(bounds, { padding: [90, 90] });
      }
    },

    selectArea(area) {
      this.cancelAreaEdit();
      this.selectedPredefinedArea = area;
      this.setAreaInMap(area);
    },

    unselectArea() {
      this.selectedPredefinedArea = null;
      this.removePolygon();
    },

    deleteArea(area) {
      this.$refs.confirm
        .open(
          this.$t('areas.deleteArea'),
          this.$t('areas.areYouSure', { name: area.name }),
          this.$t('delete'),
          this.$t('cancel'),
          { color: 'primary' },
        )
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('user/deleteArea', area.id);
            this.selectedPredefinedArea = null;
            this.removePolygon();
          }
        });
    },

    startDrawing() {
      this.$refs.map.startPolygon();
    },

    startedDrawing() {
      this.isDrawing = true;
      this.polygonError = false;
    },

    stoppedDrawing() {
      this.isDrawing = false;
    },

    async saveArea() {
      this.$refs.formInstance.validate();
      if (!this.areaEdit.polygon) {
        this.polygonError = true;
      }

      if (!this.validAndHasPolygon) {
        return;
      }

      const body = {
        name: this.areaEdit.name,
        description: this.areaEdit.description,
        multiPolygon: convertToMultiPolygon(this.areaEdit.polygon),
      };

      if (!this.selectedPredefinedArea) {
        try {
          const res = await this.$store.dispatch('user/createArea', body);
          this.justCreatedAreaId = res.id;
        } catch (error) {
          if (!error.hasBeenHandled) {
            alert(error);
          }
        }
      } else {
        body.id = this.selectedPredefinedArea.id;
        try {
          await this.$store.dispatch('user/updateArea', body);
        } catch (error) {
          if (!error.hasBeenHandled) {
            alert(error);
          }
        }
      }

      this.$refs.map.stopDrawing();
      this.areaEdit.name = '';
      this.areaEdit.description = '';
      this.areaEdit.polygon = null;
      this.areaEditMode = false;
      this.isDrawing = false;
    },

    startAreaCreation() {
      this.areaEdit = {
        name: '',
        description: '',
        polygon: null,
      };
      this.unselectArea();
      this.areaEditMode = true;
    },

    startAreaEdit(area) {
      this.areaEditMode = true;
      this.areaEdit = {
        ...area,
      };

      const mapComponent = this.$refs.map;
      const map = mapComponent.map;
      let currentLayer = null;
      map.eachLayer((layer) => {
        if (layer.id === 'areaPolygon_' + area.id) {
          currentLayer = layer;
        }
      });
      if (currentLayer) {
        mapComponent.enableEdit(currentLayer);
      }
    },

    cancelAreaEdit() {
      this.areaEditMode = false;
      this.$refs.map.stopDrawing();
      if (this.selectedPredefinedArea) {
        this.setAreaInMap(this.selectedPredefinedArea);
      }
    },

    removePolygon(fitToLimits = false) {
      const mapComponent = this.$refs.map;
      const map = mapComponent.map;
      if (this.drawnItems) {
        map.removeLayer(this.drawnItems);
        mapComponent.stopDrawing();

        if (fitToLimits) {
          // reset to limits
          this.$refs.map.fitToLimits();
        }
      }
    },

    stringMaxLength(string, maxLength, addEllipsis = true) {
      return stringTools.truncate(string, maxLength, addEllipsis);
    },
  },
};
</script>

<style lang="scss" scoped>
.area-list {
  background-color: white;
  border-radius: 8px;
  height: 100%;
  padding: 8px;
  transition: all 0.2s; /* Animation */
}

.area-info {
  position: absolute;
  top: 0;
  z-index: 100;
  margin: 8px;
  min-width: 400px;
  padding: 8px;
}

.areaDetailsCard {
  background-color: aliceblue;
}

.close-card-btn {
  position: absolute;
  right: 8px;
  top: 8px;
}

.areas-table ::v-deep tbody {
  tr.current {
    background-color: aliceblue;
  }

  td {
    cursor: pointer;
  }
}
</style>
